import { styled } from 'src/styles/stitches.config'

export const StyledInput = styled('div', {
    position: 'relative',
    input: {
        width: '100%',
        height: '50px',
        verticalAlign: 'baseline',
        padding: '1.2rem 2rem',
        fontSize: '1rem',
        border: 'none',
        backgroundColor: 'transparent',
        color: '$black',
        '&:focus + label,&:not(:placeholder-shown) + label': {
            top: '5px',
            fontSize: '0.85rem',
            letterSpacing: 0,
        },
        '&::placeholder': {
            opacity: 0,
        },
    },
})

export const StyledDatePicker = styled('div', {
    marginTop: '0!important',
    paddingLeft: '$xs',
    width: '100%',
    input: {
        padding: 0,
    },
    '.react-date-picker': {
        width: '100%',
    },
    '.react-date-picker__wrapper': {
        border: 'none',
    },
    '.react-date-picker__calendar': {
        zIndex: 2,
    },
    '.react-calendar button, .react-calendar__month-view__weekdays': {
        color: '$blueDark',
        '&.react-calendar__month-view__days__day--weekend': {
            color: '#900',
        },
    },
    '.react-calendar__navigation__label': {
        textTransform: 'capitalize',
    },
})
