import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import {
    StyledContent,
    StyledImageWrapper,
    StyledPostCard,
} from './PostCard.style'

export type PostCardProps = {
    image: CmsImage
    title: string
    excerpt: string
    link: CmsLink
    forceReveal?: boolean
}

const PostCard: React.FC<PostCardProps> = ({
    image,
    title,
    excerpt,
    link,
    forceReveal,
}) => {
    return (
        <StyledPostCard as={Padder} top="md">
            <Link to={link.url} style={{ width: '100%' }}>
                <StyledImageWrapper>
                    {forceReveal ? (
                        <Image src={image.url} alt={image.alt} />
                    ) : (
                        <ImageReveal src={image.url} alt={image.alt} />
                    )}
                </StyledImageWrapper>
            </Link>
            <Revealer forceReveal={forceReveal}>
                <Padder top="md" className="content">
                    <Padder top="sm">
                        <Text as="h4" textVariant="heading4">
                            {title}
                        </Text>
                        <StyledContent>
                            <RichText htmlString={excerpt} textVariant="base" />
                        </StyledContent>
                    </Padder>
                    <LinkButton {...link} textVariant="base" />
                </Padder>
            </Revealer>
        </StyledPostCard>
    )
}

export default PostCard
