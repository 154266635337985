import Button from 'src/components/atoms/Button/Button'
import {
    StyledDatePicker,
    StyledInput,
} from 'src/components/atoms/Input/Input.style'
import { styled } from 'src/styles/stitches.config'

export const StyledHorizontalBooking = styled('div', {
    backgroundColor: '#172235',
    paddingLeft: 'calc(2 * $colWidth)',
    marginTop: '0!important',
    color: '$white',
    '--select-background': 'var(--colors-blueDark)',
    '--select-singleValue': 'var(--colors-white)',

    '@lg': {
        height: '15vh',
    },

    [`${StyledInput} input`]: {
        color: '$white',
        borderColor: '$white',
        backgroundColor: '$blueDark!important',
        '&:placeholder': {
            color: 'white',
        },
        '&::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)',
        },
    },
    svg: {
        stroke: '$white!important',
    },
    '.react-date-picker': {
        marginTop: '$xxs',

        backgroundColor: '$blueDark!important',
    },
    [`${StyledDatePicker}`]: {
        paddingLeft: 0,
    },
})

//Erase:
export const DateWrapper = styled('div', {
    width: 'calc(4 * $colWidth)',
})

export const SubmitWrapper = styled('div', {
    width: 'calc(5 * $colWidth)',
    height: '100%',
    [`${Button}`]: {
        width: '100%',
        height: '100%',
    },
})

export const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'var(--colors-blueDark)',
        border: 'none',
        boxShadow: 'none!important',
        height: '50px',
        marginTop: 'var(--space-xs)',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'var(--colors-white)',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected
            ? '#ddd'
            : state.isFocused
            ? '#eee'
            : '#fff',
    }),
}
