import {
    DateWrapper,
    selectStyles,
    StyledBookingForm,
    SubmitWrapper,
} from './BookingForm.style'
import './BookingForm.css'
import Button from 'src/components/atoms/Button/Button'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Input from 'src/components/atoms/Input/Input'
import Select from 'react-select'
import useLabel from 'src/hooks/useLabel'
import AdultsSelect from '../AdultsSelect/AdultsSelect'
import { useCallback, useRef, useState } from 'react'
import RoomSelect from '../RoomSelect/RoomSelect'
import { useSelector } from 'react-redux'
import { getSettings } from 'src/selectors/settings'
import Text from 'src/components/atoms/Text/Text'

const BookingForm: React.FC<{ column?: boolean }> = ({ column }) => {
    const { booking } = useSelector(getSettings)
    const label = useLabel()
    const selectRef = useRef<any>()
    const adultsRef = useRef<any>()
    const [checkinDate, setCheckinDate] = useState<Date>(new Date())
    const [checkoutDate, setCheckoutDate] = useState<Date>(new Date())
    const onFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
        (e) => {
            e.preventDefault()
            const params = {
                mphb_check_in_date:
                    checkinDate?.toISOString().split('T')[0] || '',
                mphb_check_out_date:
                    checkoutDate?.toISOString().split('T')[0] || '',
                //room: selectRef.current.getValue()[0].value,
                mphb_adults: adultsRef.current.getValue()[0].value,
                mphb_children: 0,
            }

            const stringParams = Object.keys(params)
                //@ts-ignore
                .map((key) => key + '=' + params[key])
                .join('&')

            window.location.href = `${booking.link.url}?${stringParams}`
        },
        [booking.link.url, checkinDate, checkoutDate]
    )

    return (
        <StyledBookingForm onSubmit={onFormSubmit}>
            <FlexWrapper
                as={Padder}
                css={!column ? { height: '100%' } : undefined}
                {...(column ? { top: 'sm' } : { left: 'sm' })}
                justify={column ? 'start' : 'between'}
                align={column ? 'start' : 'center'}
                direction={column ? 'column' : 'row'}
            >
                <DateWrapper css={column ? { width: '100%' } : undefined}>
                    <br />
                    <Input
                        type="date"
                        name="check-in"
                        label={label('Check-in') + ':'}
                        //@ts-ignore
                        value={checkinDate}
                        //@ts-ignore
                        onChange={setCheckinDate}
                        maxDate={
                            booking.maxDate
                                ? new Date(booking.maxDate)
                                : undefined
                        }
                    />
                </DateWrapper>
                <DateWrapper css={column ? { width: '100%' } : undefined}>
                    <Input
                        type="date"
                        name="check-out"
                        label={label('Check-out') + ':'}
                        //@ts-ignore
                        value={checkoutDate}
                        //@ts-ignore
                        onChange={setCheckoutDate}
                        maxDate={
                            booking.maxDate
                                ? new Date(booking.maxDate)
                                : undefined
                        }
                    />
                </DateWrapper>
                <FlexWrapper
                    fitContent
                    direction="column"
                    css={column ? { width: '100%' } : undefined}
                >
                    {label('Select room') + ':'}
                    <RoomSelect
                        ref={selectRef}
                        defaultValue={{
                            value: booking?._rooms[0]?.name,
                            label: booking?._rooms[0]?.name,
                        }}
                        styles={selectStyles}
                    />
                </FlexWrapper>
                <FlexWrapper
                    fitContent
                    direction="column"
                    css={column ? { width: '100%' } : undefined}
                >
                    {label('Adults:')}
                    <AdultsSelect
                        ref={adultsRef}
                        defaultValue={{
                            value: '1',
                            label: '1',
                        }}
                        styles={selectStyles}
                    />
                </FlexWrapper>
                <SubmitWrapper>
                    <Button variant={column ? 'blueFill' : 'whiteFill'}>
                        <Text textVariant="base2" css={{ fontWeight: '$bold' }}>
                            {label('Book now').toUpperCase()}
                        </Text>
                    </Button>
                </SubmitWrapper>
            </FlexWrapper>
        </StyledBookingForm>
    )
}

export default BookingForm
