import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchMenuFromServer, fetchSettingsFromServer } from 'src/api/settings'

import { FooterType, SettingsSliceState } from './types'

const initialState: SettingsSliceState = {
    ready: false,
    slugs: {},
    logo: '',
    language: 'en',
    languages: {},
    ajaxurl: '',
    social: {},
    telephone: '',
    components: [],
    footer: {} as FooterType,
    labels: {},
    menu: [],
    popup: {
        isActive: false,
        image: undefined,
    },
    booking: {},
} as SettingsSliceState

export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async (language: string) => {
        const response = await fetchSettingsFromServer(language)
        return response
    }
)

export const fetchMenu = createAsyncThunk(
    'settings/fetchMenu',
    async (language: string) => {
        const response = await fetchMenuFromServer(language)
        return response
    }
)

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            return {
                ...state,
                ready: true,
                ...action.payload,
            }
        })
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            return {
                ...state,
                menu: action.payload,
            }
        })
    },
})

export default settingsSlice.reducer
