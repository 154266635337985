import { styled } from 'src/styles/stitches.config'

export const StyledFooter = styled('div', {
    marginTop: '$lg',
    padding: '$md 0',
    backgroundColor: '$white',
    '@md': {
        marginTop: '$xxl',
    },
})

export const StyledPreFooter = styled('div', {
    backgroundImage:
        'linear-gradient(to bottom, $whiteSmoke 17%,$white 17%, $white 100%)',
    '@md': {
        backgroundImage:
            'linear-gradient(to bottom, $whiteSmoke 33%,$white 30%, $white 100%)',
    },
})

export const FooterLogo = styled('div', {
    width: '60px',
    '@md': {
        width: 'calc($colWidth / 2)',
    },
})

export const StyledFooterExtra = styled('div', {
    img: {
        width: 'auto',
        height: '60px',
    },
})
