import { styled } from 'src/styles/stitches.config'

export const StyledHomeSlider = styled('div', {
    backgroundColor: '$blue',
    '@md': {
        height: '85vh',
        minHeight: 750,
    },
})

export const SliderContainer = styled('div', {
    height: '100%',

    '@mdDown': {
        paddingTop: '5rem',
    },
})

export const SliderGrid = styled('div', {
    '@md': {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '30% 70%',
        placeContent: 'center',
        alignItems: 'center',
    },
    '@mdDown': {
        display: 'flex',
        flexDirection: 'column-reverse',

        textAlign: 'center',
        width: '100%',
        '.description': {
            textAlign: 'center',
        },

        '> *': {
            paddingTop: '30px',
        },
    },
})

export const SliderWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    gridColumnStart: 2,

    img: {
        width: '100%',
        height: 'auto',
        '@mdDown': {
            objectFit: 'cover',
        },
    },

    '.swiper-slide': {
        width: '100%',
        '@mdDown': {
            ratio: {
                w: 3,
                h: 2,
            },
        },
    },
})

export const InfoWrapper = styled('div', {
    position: 'relative',
    gridColumnStart: 1,
    width: '100%',
    zIndex: 2,
    color: '$white',
    '@md': {
        width: '130%',
    },
})

export const NavButton = styled('button', {
    fontSize: '2rem',
})
