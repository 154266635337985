import { styled } from 'src/styles/stitches.config'

export const StyledItem = styled('div', {
    position: 'relative',
    '@md': {
        width: '33%',
        ratio: {
            w: 3,
            h: 2,
        },
    },
    '@lg': {
        width: '25%',
    },

    '@mdDown': {
        '&:not(:nth-child(2))': {
            backgroundColor: '$whiteSmoke',
        },
    },
})

export const StyledItemBackground = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '$whiteSmoke',
    zIndex: 0,

    '@mdDown': {
        display: 'none',
    },
})

export const StyledItemContent = styled('div', {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '$lg',
})
