import { styled } from 'src/styles/stitches.config'

const Button = styled('button', {
    display: 'inline-block',
    border: 'solid 1px $black',
    width: 'auto',
    padding: '$xxs $xs',

    variants: {
        variant: {
            white: {
                border: 'solid 1px $white',
                color: '$white',
            },

            whiteFill: {
                color: '$black',
                backgroundColor: '$white',
                border: 'solid 1px $white',
            },
            blue: {
                border: 'solid 1px $blueDark',
                color: '$blueDark',
            },
            blueFill: {
                border: 'solid 1px $blueDark',
                color: '$white',
                backgroundColor: '$blueDark',
            },
        },
        round: {
            true: {
                borderRadius: '15px',
                overflow: 'hidden',
                fontSize: '1.5rem',
                fontFamily: '$secondary',
            },
        },
    },
})

export default Button
