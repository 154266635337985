import { StyledInput } from 'src/components/atoms/Input/Input.style'
import { styled } from 'src/styles/stitches.config'

export const StyledFormField = styled('div', {
    width: '100%',
    backgroundColor: '$white!important',
    display: 'flex',
    alignItems: 'center',
    padding: '$xs',
    [`${StyledInput}, .input-row`]: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        width: '100%',
        label: {
            whiteSpace: 'nowrap',
            wordBreak: 'keep',
            width: 'max-content',
            fontWeight: 'bold',
        },
        input: {
            color: '$black',
            borderColor: '$white',
            backgroundColor: '$white!important',
            marginTop: 0,
            width: '100%',
            '&:placeholder': {
                color: '$black',
            },
        },

        '::-webkit-datetime-edit-day-field': { color: '#f00' },
    },
})

export const selectStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'var(--colors-white)',
        border: 'none',
        boxShadow: 'none!important',
        height: '50px',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        width: '100%',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'var(--colors-black)',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected
            ? '#ddd'
            : state.isFocused
            ? '#eee'
            : '#fff',
    }),
}
