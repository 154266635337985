import { styled } from 'src/styles/stitches.config'

export const StyledPostCard = styled('div', {
    paddingBottom: '$xxs',
})

export const StyledImageWrapper = styled('div', {
    ratio: {
        w: 3,
        h: 2,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
})

export const StyledContent = styled('div', {
    '@md': {
        width: '60%',
    },
})
