import { CmsImage } from 'src/types'
import { StyledGallery } from './Gallery.style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperInstance from 'swiper'
import { useRef } from 'react'
import Image from 'src/components/atoms/Image/Image'
import Padder from 'src/components/_layout/Padder/Padder'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import useLabel from 'src/hooks/useLabel'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import {
    HiOutlineArrowNarrowLeft,
    HiOutlineArrowNarrowRight,
} from 'react-icons/hi'

type GalleryProps = {
    images: Array<CmsImage>
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const swiperRef = useRef<SwiperInstance>()
    const label = useLabel()
    return (
        <Padder top={{ '@initial': 'sm', '@md': 'lg' }}>
            <Container>
                <FlexWrapper
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    justify={{ '@initial': 'start', '@md': 'between' }}
                    align={{ '@initial': 'center', '@md': 'start' }}
                >
                    <Text as="h4" textVariant="heading3">
                        {label('Photo gallery')}
                    </Text>
                    <FlexWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'sm' }}
                        top={{ '@initial': 'sm', '@md': 'zero' }}
                        align="center"
                    >
                        <Text
                            textVariant="base2"
                            textTransform="uppercase"
                            css={{
                                fontWeight: '$bold',
                                '@mdDown': { display: 'none' },
                            }}
                        >
                            <strong>{label('show_all_images')}</strong>
                        </Text>
                        <FlexWrapper css={{ gap: '1rem', fontSize: '2rem' }}>
                            <button
                                type="button"
                                onClick={() => swiperRef.current?.slidePrev()}
                            >
                                <HiOutlineArrowNarrowLeft />
                            </button>
                            <button
                                type="button"
                                onClick={() => swiperRef.current?.slideNext()}
                            >
                                <HiOutlineArrowNarrowRight />
                            </button>
                        </FlexWrapper>
                    </FlexWrapper>
                </FlexWrapper>
            </Container>
            <StyledGallery
                as={Swiper}
                slidesPerView="auto"
                spaceBetween={80}
                loop
                centeredSlides
                onSwiper={(swiper) => {
                    swiperRef.current = swiper
                }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Image src={image.url} alt={image.alt} />
                    </SwiperSlide>
                ))}
            </StyledGallery>
        </Padder>
    )
}

export default Gallery
