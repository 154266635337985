import { styled } from 'src/styles/stitches.config'

export const StyledTitle = styled('div', {
    textAlign: 'center',
})

export const StyledSubtitle = styled('div', {
    width: '80%',

    margin: 'auto',
    '@md': {
        maxWidth: 'calc($colWidth * 6)',
    },
})

export const StyledSlider = styled('div', {
    position: 'relative',
    paddingBottom: '$xxs',
    '.swiper-slide': {
        opacity: 0.5,
        width: '70%',
        transition: 'opacity .7s linear',
        pointerEvents: 'none',
        '@md': {
            width: 'calc(8 * $colWidth)',
        },
    },
    '.swiper-slide-active': {
        opacity: 1,
        pointerEvents: 'all',
    },
})

export const StyledNavigation = styled('div', {
    fontSize: '2rem',

    '@md': {
        position: 'absolute',
        bottom: 0,
        right: 'calc(2 * $colWidth)',
        zIndex: 1,
    },

    '@mdDown': {
        display: 'flex',
        justifyContent: 'center',
    },
})
