import { styled } from 'src/styles/stitches.config'

export const StyledImageLaunch = styled('div', {})

export const StyledImageWrapper = styled('div', {
    width: '100%',
    paddingBottom: '$lg',
    '@lg': {
        width: 'calc(9 * $colWidth)',
    },
})

export const StyledImageGrid = styled('div', {
    variants: {
        grid: {
            true: {
                display: 'grid',
                gridTemplateColumns: '33% 20%',
                gridTemplateRows: '48% 38%',
            },
        },
    },
})

export const StyledVerticalImageWrapper = styled('div', {
    position: 'relative',
    zIndex: 1,

    variants: {
        grid: {
            true: {
                gridColumn: '2 / 4',
                gridRow: '1 / 4',
                ratio: {
                    w: 41,
                    h: 62,
                },
            },
            false: {
                img: {
                    width: '100%',
                },
            },
        },
    },
})

export const StyledHorizontalImageWrapper = styled('div', {
    position: 'relative',
    zIndex: 2,
    gridColumn: '1 / 3',
    gridRowStart: 2,
    ratio: {
        w: 3,
        h: 2,
    },
})

export const StyledDescription = styled('div', {
    width: '80%',
})

export const StyledContentWrapper = styled('div', {
    '@lg': {
        width: 'calc(10 * $colWidth)',
        paddingRight: 'calc(2 * $colWidth)',
    },

    variants: {
        inverted: {
            true: {
                paddingRight: '0',
                paddingLeft: 'calc(2 * $colWidth)',
            },
        },
        grid: {
            true: {
                '@md': {
                    width: 'calc(8 * $colWidth)',
                },
            },
            false: {
                [`${StyledDescription}`]: {
                    width: '100%',
                },
            },
        },
    },
})
