import { AnimateSharedLayout, motion } from 'framer-motion'
import { useState } from 'react'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsLink } from 'src/types'
import {
    StyledItem,
    StyledItemBackground,
    StyledItemContent,
} from './FeaturedPages.style'

type FeaturedPageItem = {
    title: string
    description: string
    link: CmsLink
}

type FeaturedPageProps = {
    items: Array<FeaturedPageItem>
}

const FeaturedPages: React.FC<FeaturedPageProps> = ({ items }) => {
    const [currentHover, setCurrentHover] = useState(0)
    return (
        <FlexWrapper
            justify={{ '@initial': 'start', '@md': 'around' }}
            direction={{ '@initial': 'column', '@md': 'row' }}
        >
            <AnimateSharedLayout>
                {items.map(({ title, description, link }, index) => (
                    <StyledItem
                        key={index}
                        onMouseEnter={() => {
                            setCurrentHover(index)
                        }}
                    >
                        <StyledItemContent as={Padder} top="xs">
                            <Revealer>
                                <Text as="h4" textVariant="heading3Bis">
                                    {title}
                                </Text>
                            </Revealer>
                            <Revealer>
                                <RichText
                                    htmlString={description}
                                    textVariant="base"
                                    align="center"
                                />
                            </Revealer>
                            <Revealer>
                                <LinkButton {...link} textVariant="base" />
                            </Revealer>
                        </StyledItemContent>
                        {currentHover === index && (
                            <StyledItemBackground
                                as={motion.div}
                                layoutId="outline"
                            />
                        )}
                    </StyledItem>
                ))}
            </AnimateSharedLayout>
        </FlexWrapper>
    )
}

export default FeaturedPages
