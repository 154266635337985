import { styled } from 'src/styles/stitches.config'

export const StyledDescription = styled('div', {
    width: '80%',
})

export const StyledChunk = styled('div', {
    '@mdDown': {
        marginTop: '$md',
    },
    '@md': {
        width: 'calc(9 * $colWidth)',
        '&:nth-child(2n)': {
            paddingTop: '$xxl',
        },
    },
})

export const StyledCard = styled('div', {})
