import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import {
    StyledContentWrapper,
    StyledDescription,
    StyledHorizontalImageWrapper,
    StyledImageGrid,
    StyledImageLaunch,
    StyledImageWrapper,
    StyledVerticalImageWrapper,
} from './ImageLaunch.style'

type ImageLaunchProps = {
    title: string
    description: string
    images: Array<CmsImage>
    link?: CmsLink
    inverted?: boolean
}

const ImageLaunch: React.FC<ImageLaunchProps> = ({
    title,
    description,
    images,
    link,
    inverted,
}) => {
    const [firstImage, secondImage] = useMemo(() => images, [images])
    return (
        <Container>
            <FlexWrapper
                justify="between"
                align="center"
                css={{
                    paddingTop: !secondImage ? '$xxl' : 0,
                }}
                direction={{
                    '@initial': 'column',
                    '@md': inverted ? 'rowReverse' : 'row',
                }}
            >
                <StyledImageWrapper>
                    <StyledImageGrid grid={!!secondImage}>
                        <StyledVerticalImageWrapper grid={!!secondImage}>
                            <ImageReveal src={firstImage.url} />
                        </StyledVerticalImageWrapper>
                        {!!secondImage && (
                            <StyledHorizontalImageWrapper>
                                <ImageReveal src={secondImage.url} />
                            </StyledHorizontalImageWrapper>
                        )}
                    </StyledImageGrid>
                </StyledImageWrapper>
                <StyledContentWrapper grid={!!secondImage}>
                    <Padder top="md">
                        <Revealer>
                            <Text
                                as="h4"
                                textVariant="heading3"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </Revealer>
                        <Revealer>
                            <Padder top="lg">
                                <StyledDescription>
                                    <RichText
                                        textVariant="base"
                                        htmlString={description}
                                    />
                                </StyledDescription>
                                {link && (
                                    <LinkButton {...link} textVariant="base" />
                                )}
                            </Padder>
                        </Revealer>
                    </Padder>
                </StyledContentWrapper>
            </FlexWrapper>
        </Container>
    )
}

export default ImageLaunch
