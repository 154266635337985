import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import { StyledPageTitle } from './PageTitle.style'

type PageTitleProps = {
    title: string
    description?: string
    preventPadding?: boolean
}
const PageTitle: React.FC<PageTitleProps> = ({
    title,
    description,
    preventPadding,
}) => {
    return (
        <StyledPageTitle
            as={Container}
            css={preventPadding ? { paddingTop: 0 } : undefined}
        >
            <Padder top="md">
                <Text textVariant="heading3" align="center">
                    {title}
                </Text>
                {description && (
                    <RichText
                        htmlString={description}
                        textVariant="base2"
                        align="center"
                    />
                )}
            </Padder>
        </StyledPageTitle>
    )
}

export default PageTitle
