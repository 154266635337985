/* eslint-disable react-hooks/exhaustive-deps */
import { RouteComponentProps } from 'react-router'
import { memo, useContext, useEffect } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import Padder from 'src/components/_layout/Padder/Padder'
import Loader from 'src/components/atoms/Loader/Loader'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import { ScrollbarContext } from 'src/context/scrollbar'
import { useGetPage } from 'src/queries'
import Footer from 'src/components/organisms/Footer/Footer'

type TParams = { lang: string; page?: string }

const Page: React.FC<RouteComponentProps<TParams>> = ({
    match: {
        params: { lang, page = 'home-page' },
    },
}) => {
    const { isLoading, error, data, isSuccess } = useGetPage(lang, page)
    const { scrollbar } = useContext(ScrollbarContext)

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                scrollbar.current.scrollbar.update()
            }, 1000)
        }
    }, [isSuccess])

    if (error) return <h1>Something went wrong!</h1>

    return (
        <AnimatePresence>
            {isLoading && <Loader />}
            {isSuccess && (
                <motion.div
                    key="content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.6 }}
                >
                    {data.acf.composer && (
                        <Padder
                            top={{
                                '@initial': 'lg',
                                '@md': data.is_front_page ? 'xxxl' : 'xl',
                            }}
                            style={{ position: 'relative' }}
                        >
                            <Composer components={data.acf.composer} />
                            <Footer disabled={data.acf.disableFooter} />
                        </Padder>
                    )}
                    {data.rendered_content && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.rendered_content,
                            }}
                        />
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default Page
