import { useResponsive } from 'ahooks'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getFooter, getSettingsComponents } from 'src/selectors/settings'
import { MenuItemType } from 'src/state/settings/types'
import { getGlobalComponent } from 'src/utils'
import {
    FooterLogo,
    StyledFooter,
    StyledFooterExtra,
    StyledPreFooter,
} from './Footer.style'

type FooterProps = {
    disabled?: boolean
}

const Footer: React.FC<FooterProps> = ({ disabled }) => {
    const footer = useSelector(getFooter)
    const components = useSelector(getSettingsComponents)
    const footerComponents = useMemo(() => {
        const footerComponentsNames = ['RoomSlider', 'FeaturedPages']
        return footerComponentsNames.map((name) =>
            getGlobalComponent(components, name)
        )
    }, [components])

    const responsiveInfo = useResponsive()

    const getLinkComponent = useCallback((item: MenuItemType) => {
        const { url } = item
        const targetValue = url.startsWith('http') ? '_blank' : undefined
        const isSpecialLink = url.startsWith('mailto') || url.startsWith('tel')
        const isA = isSpecialLink || targetValue === '_blank'
        const to = isA
            ? {
                  href: url,
              }
            : {
                  to: url,
              }
        return (
            <Text
                key={item.ID}
                as={isA ? 'a' : Link}
                {...to}
                target={targetValue}
                textVariant="small"
                align={{
                    '@initial': 'center',
                    '@md': 'left',
                }}
            >
                {item.title}
            </Text>
        )
    }, [])

    return (
        <StyledPreFooter>
            {!disabled && (
                <Padder top="xl">
                    <Composer components={footerComponents} />
                </Padder>
            )}
            <StyledFooter>
                <Container
                    as={FlexWrapper}
                    direction={{ '@initial': 'column', '@md': 'row' }}
                    align="center"
                    justify={{ '@initial': 'start', '@md': 'between' }}
                >
                    <div>
                        <FlexWrapper
                            as={Padder}
                            direction={{ '@initial': 'column', '@md': 'row' }}
                            justify={{ '@initial': 'center', '@md': 'start' }}
                            align="center"
                            left={{ '@initial': 'zero', '@md': 'colWidth' }}
                            top={{ '@initial': 'xs', '@md': 'zero' }}
                        >
                            {footer.logo && (
                                <FooterLogo>
                                    <Image src={footer.logo?.url} />
                                </FooterLogo>
                            )}
                            <Padder top="xxxs">
                                <RichText
                                    htmlString={
                                        responsiveInfo.md
                                            ? footer.description || ''
                                            : footer.descriptionMobile || ''
                                    }
                                    textVariant="small"
                                    //@ts-ignore
                                    align={{
                                        '@initial': 'center',
                                        '@md': 'left',
                                    }}
                                />
                                {responsiveInfo.md && (
                                    <FlexWrapper
                                        as={Padder}
                                        direction={{
                                            '@initial': 'column',
                                            '@md': 'row',
                                        }}
                                        left={{
                                            '@initial': 'zero',
                                            '@md': 'sm',
                                        }}
                                        top={{
                                            '@initial': 'xxs',
                                            '@md': 'zero',
                                        }}
                                        css={{
                                            '@mdDown': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        {footer?.menu1?.items.map(
                                            getLinkComponent
                                        )}
                                    </FlexWrapper>
                                )}
                            </Padder>
                        </FlexWrapper>
                    </div>
                    {!!footer.extra && (
                        <StyledFooterExtra
                            dangerouslySetInnerHTML={{ __html: footer.extra }}
                        />
                    )}
                </Container>
            </StyledFooter>
        </StyledPreFooter>
    )
}

export default Footer
