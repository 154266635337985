import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
type SelectProps = {
    styles?: any
    defaultValue?: any
}
const RoomSelect = forwardRef<any, SelectProps>(
    ({ styles, defaultValue }, ref) => {
        const {
            booking: { _rooms },
        } = useSelector(getSettings)
        return (
            <ReactSelect
                ref={ref}
                isSearchable={false}
                options={_rooms.map(({ name }: { name: string }) => ({
                    label: name,
                    value: name,
                }))}
                defaultValue={defaultValue}
                styles={styles}
            />
        )
    }
)

export default RoomSelect
