import { styled } from 'src/styles/stitches.config'

export const Social = styled('button', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    backgroundColor: '$blueDark',
    color: '$white',
    borderRadius: '50%',
    fontSize: '0.85rem',
    a: {
        lineHeight: 0,
    },
    '@mdDown': {
        width: '1.8rem',
        height: '1.8rem',
        fontSize: '1rem',
    },
})
