import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import PostCard, {
    PostCardProps,
} from 'src/components/molecules/PostCard/PostCard'
import Padder from 'src/components/_layout/Padder/Padder'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperInstance from 'swiper'
import {
    StyledNavigation,
    StyledSlider,
    StyledSubtitle,
    StyledTitle,
} from './RoomSlider.style'
import {
    HiOutlineArrowNarrowLeft,
    HiOutlineArrowNarrowRight,
} from 'react-icons/hi'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import { useMemo, useRef } from 'react'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { useLocation } from 'react-router'
import { breakpoints } from 'src/styles/stitches.config'
import { useResponsive } from 'ahooks'
type RoomSliderProps = {
    title: string
    subtitle: string
    rooms: Array<PostCardProps>
}
const RoomSlider: React.FC<RoomSliderProps> = ({ title, subtitle, rooms }) => {
    const swiperRef = useRef<SwiperInstance>()
    const location = useLocation()
    const responsiveInfo = useResponsive()
    const Navigation = useMemo(
        () => (
            <StyledNavigation>
                <FlexWrapper css={{ gap: '1rem' }}>
                    <button
                        type="button"
                        onClick={() => swiperRef.current?.slidePrev()}
                    >
                        <HiOutlineArrowNarrowLeft />
                    </button>
                    <button
                        type="button"
                        onClick={() => swiperRef.current?.slideNext()}
                    >
                        <HiOutlineArrowNarrowRight />
                    </button>
                </FlexWrapper>
            </StyledNavigation>
        ),
        []
    )

    return (
        <Padder top={{ '@initial': 'md', '@md': 'xl' }}>
            <Revealer>
                <StyledTitle as={Padder} top="sm">
                    <Text textVariant="heading3">{title}</Text>
                    <StyledSubtitle>
                        <RichText
                            htmlString={subtitle}
                            textVariant="base"
                            align="center"
                        />
                    </StyledSubtitle>
                    {!responsiveInfo.md && Navigation}
                </StyledTitle>
            </Revealer>
            <StyledSlider>
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={30}
                    loop
                    centeredSlides
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper
                    }}
                    breakpoints={{
                        [breakpoints.md]: {
                            spaceBetween: 80,
                        },
                    }}
                >
                    {rooms
                        .filter((room) => room.link.url !== location.pathname)
                        .map((room, index) => (
                            <SwiperSlide key={index}>
                                <PostCard {...room} forceReveal />
                            </SwiperSlide>
                        ))}
                </Swiper>
                {responsiveInfo.md && Navigation}
            </StyledSlider>
        </Padder>
    )
}

export default RoomSlider
