import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import Container from 'src/components/_layout/Container/Container'
import { CmsImage } from 'src/types'
import { StyledBanner } from './Banner.style'

const Banner: React.FC<{ image: CmsImage }> = ({ image: { url, alt } }) => {
    return (
        <Container>
            <ImageReveal src={url} alt={alt} />
        </Container>
    )
}

export default Banner
