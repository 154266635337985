import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import { getSettings } from 'src/selectors/settings'

import { StyledLogo } from './Logo.style'

const Logo: React.FC = (props) => {
    const { logo, language } = useSelector(getSettings)

    return (
        <StyledLogo
            as={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
        >
            <Link to={`/${language}`}>
                <svg
                    viewBox="0 0 152.7 35.77"
                    style={{ width: '100%' }}
                    className="logo-svg"
                >
                    <g id="Livello_1-2" data-name="Livello 1">
                        <path d="m28.2,11V0h4.6v27.7h-4.6v-4.8c-2.6,2.2-5.43,3.87-8.5,5-5.07,1.93-9.77,1.83-14.1-.3-2-1.13-3.43-2.47-4.3-4-.87-1.47-1.3-2.97-1.3-4.5s.37-3.03,1.1-4.5c1-1.73,2.5-3.23,4.5-4.5,3.73-2,8.1-2.63,13.1-1.9,3.6.53,6.77,1.47,9.5,2.8Zm-10.1,1.8c-4-.6-7.37-.17-10.1,1.3-1.33.8-2.27,1.7-2.8,2.7-.4.73-.6,1.5-.6,2.3,0,.73.2,1.43.6,2.1.53.87,1.4,1.67,2.6,2.4,3.07,1.47,6.5,1.47,10.3,0,4.07-1.53,7.43-3.97,10.1-7.3-2.13-1.67-5.5-2.83-10.1-3.5Z" />
                        <path d="m81.1,27.7h-4.6V0h4.6v27.7Z" />
                        <path d="m88.5.7c.67.53,1,1.17,1,1.9,0,.73-.33,1.37-1,1.9-.6.53-1.33.8-2.2.8s-1.63-.27-2.3-.8c-.6-.53-.9-1.17-.9-1.9s.3-1.37.9-1.9C84.67.23,85.43,0,86.3,0s1.6.23,2.2.7Zm.1,27h-4.6V8h4.6v19.7Z" />
                        <path d="m96.1,10.1c1.73-1.33,3.67-2.3,5.8-2.9,2.27-.67,4.5-.87,6.7-.6,2.4.2,4.4,1.03,6,2.5,1.6,1.4,2.43,3.4,2.5,6v12.6h-4.6v-12.5c0-1.2-.37-2.13-1.1-2.8-.8-.73-1.9-1.17-3.3-1.3-1.53-.13-3.17.03-4.9.5-3.2.93-5.57,2.7-7.1,5.3v10.8h-4.6V8h4.6v2.1Z" />
                        <path d="m143.4,28.6c-1.87.4-3.83.6-5.9.6-4.8,0-8.93-.9-12.4-2.7-4-2.07-6-4.7-6-7.9s2-5.93,6-8c3.47-1.8,7.6-2.7,12.4-2.7,4.4,0,7.8.87,10.2,2.6,3.2,2.2,4.83,4.73,4.9,7.6l.1,1.8-1.6.5c-3.8,1.33-7.97,2.17-12.5,2.5-3.73.2-7.97-.2-12.7-1.2.4.2.83.43,1.3.7,2.8,1.47,6.23,2.2,10.3,2.2,1.8,0,3.5-.17,5.1-.5,1.6-.27,3.03-.7,4.3-1.3l1.8,4.3c-1.6.67-3.37,1.17-5.3,1.5h0Zm-18.8-12c5.33,1.4,9.93,1.97,13.8,1.7,3.27-.2,6.33-.73,9.2-1.6-.47-.87-1.27-1.63-2.4-2.3-1.67-1.27-4.23-1.9-7.7-1.9-4.07,0-7.5.73-10.3,2.2-1.13.6-2,1.23-2.6,1.9Z" />
                        <g id="HOLIDAY_ROOMS" data-name="HOLIDAY ROOMS">
                            <g>
                                <path d="m50.22,35.69v-4.29h.57v1.76h2.23v-1.76h.57v4.29h-.57v-2.02h-2.23v2.02h-.57Z" />
                            </g>
                            <g>
                                <path d="m55.36,33.6c0-.71.19-1.27.57-1.67s.88-.61,1.48-.61c.4,0,.75.09,1.07.28s.56.45.73.79c.17.34.25.72.25,1.15s-.09.83-.26,1.17-.42.6-.75.78-.67.26-1.04.26c-.4,0-.77-.1-1.08-.29s-.56-.46-.72-.8-.25-.7-.25-1.07Zm.59,0c0,.52.14.93.42,1.22s.63.45,1.05.45.78-.15,1.06-.45.41-.73.41-1.28c0-.35-.06-.65-.18-.92-.12-.26-.29-.46-.52-.61-.23-.14-.48-.22-.77-.22-.4,0-.75.14-1.04.42s-.44.74-.44,1.38Z" />
                            </g>
                            <g>
                                <path d="m61.18,35.69v-4.29h.57v3.79h2.12v.51h-2.68Z" />
                            </g>
                            <g>
                                <path d="m65.64,35.69v-4.29h.57v4.29h-.57Z" />
                            </g>
                            <g>
                                <path d="m68.21,35.69v-4.29h1.48c.33,0,.59.02.76.06.25.06.46.16.63.31.23.19.4.44.51.73s.17.64.17,1.02c0,.33-.04.62-.11.87s-.17.46-.29.63-.25.29-.39.39c-.14.1-.31.17-.51.22s-.43.07-.69.07h-1.55Zm.57-.51h.92c.28,0,.51-.03.67-.08s.29-.13.39-.22c.13-.13.24-.32.31-.54s.11-.5.11-.83c0-.45-.07-.79-.22-1.04s-.33-.4-.54-.49c-.15-.06-.4-.09-.74-.09h-.9v3.28Z" />
                            </g>
                            <g>
                                <path d="m73.07,35.69l1.65-4.29h.61l1.76,4.29h-.65l-.5-1.3h-1.8l-.47,1.3h-.6Zm1.24-1.76h1.46l-.45-1.19c-.14-.36-.24-.66-.3-.89-.05.28-.13.55-.23.82l-.47,1.26Z" />
                            </g>
                            <g>
                                <path d="m79.31,35.69v-1.82l-1.66-2.48h.69l.85,1.29c.16.24.3.48.44.73.13-.22.29-.48.47-.76l.83-1.26h.66l-1.71,2.48v1.82h-.57Z" />
                            </g>
                            <g>
                                <path d="m85.67,35.69v-4.29h1.9c.38,0,.67.04.87.12.2.08.36.21.48.41s.18.41.18.65c0,.3-.1.56-.3.77s-.5.34-.91.4c.15.07.26.14.34.21.17.15.32.34.47.57l.75,1.17h-.71l-.57-.89c-.17-.26-.3-.46-.41-.59s-.2-.23-.29-.29c-.09-.05-.17-.09-.26-.11-.06-.01-.17-.02-.32-.02h-.66v1.91h-.57Zm.57-2.4h1.22c.26,0,.46-.03.61-.08.15-.05.26-.14.33-.26.08-.12.11-.25.11-.38,0-.2-.07-.37-.22-.5-.15-.13-.38-.2-.7-.2h-1.36v1.42Z" />
                            </g>
                            <g>
                                <path d="m90.82,33.6c0-.71.19-1.27.57-1.67s.88-.61,1.48-.61c.4,0,.75.09,1.07.28s.56.45.73.79c.17.34.25.72.25,1.15s-.09.83-.26,1.17-.42.6-.75.78-.67.26-1.04.26c-.4,0-.77-.1-1.08-.29s-.56-.46-.72-.8-.25-.7-.25-1.07Zm.59,0c0,.52.14.93.42,1.22.28.3.63.45,1.05.45s.78-.15,1.06-.45c.28-.3.41-.73.41-1.28,0-.35-.06-.65-.18-.92-.12-.26-.29-.46-.52-.61-.23-.14-.48-.22-.77-.22-.4,0-.75.14-1.04.42-.29.28-.44.74-.44,1.38Z" />
                            </g>
                            <g>
                                <path d="m96.48,33.6c0-.71.19-1.27.57-1.67s.88-.61,1.48-.61c.4,0,.75.09,1.07.28s.56.45.73.79c.17.34.25.72.25,1.15s-.09.83-.26,1.17-.42.6-.75.78-.67.26-1.04.26c-.4,0-.77-.1-1.08-.29s-.56-.46-.72-.8-.25-.7-.25-1.07Zm.59,0c0,.52.14.93.42,1.22.28.3.63.45,1.05.45s.78-.15,1.06-.45c.28-.3.42-.73.42-1.28,0-.35-.06-.65-.18-.92-.12-.26-.29-.46-.52-.61-.23-.14-.48-.22-.77-.22-.4,0-.75.14-1.04.42-.29.28-.43.74-.43,1.38Z" />
                            </g>
                            <g>
                                <path d="m102.31,35.69v-4.29h.86l1.02,3.04c.09.28.16.5.21.64.05-.16.12-.39.23-.69l1.03-2.99h.76v4.29h-.55v-3.59l-1.25,3.59h-.51l-1.24-3.66v3.66h-.55Z" />
                            </g>
                            <g>
                                <path d="m108.13,34.32l.54-.05c.03.21.08.39.18.53.09.14.24.25.43.33.2.09.42.13.66.13.22,0,.41-.03.57-.1s.29-.15.37-.27c.08-.11.12-.23.12-.37s-.04-.25-.12-.35c-.08-.1-.21-.18-.39-.25-.12-.04-.37-.11-.76-.21-.39-.09-.67-.18-.83-.27-.21-.11-.36-.24-.46-.4-.1-.16-.15-.34-.15-.53,0-.22.06-.42.18-.61.12-.19.3-.33.54-.43s.5-.15.79-.15c.32,0,.6.05.84.15.24.1.43.25.56.45s.2.42.21.68l-.54.04c-.03-.27-.13-.48-.3-.62-.17-.14-.42-.21-.75-.21s-.59.06-.75.19c-.16.12-.24.28-.24.46,0,.15.06.28.17.38.11.1.4.2.86.31.46.1.78.2.95.27.25.12.43.26.55.44.12.18.18.38.18.61s-.07.44-.2.65c-.13.2-.32.36-.56.47-.25.11-.52.17-.83.17-.39,0-.71-.06-.98-.17-.26-.11-.47-.28-.62-.51-.15-.23-.23-.49-.24-.77Z" />
                            </g>
                        </g>
                        <path
                            id="_Path_"
                            d="m80.9,18.4c-.1.2-2.6,4.7-7.6,6.1-.93.2-1.9.3-2.9.3h-1.1l1.6,2.8h-5l-3-5.1c-3-1.67-5.43-2.23-7.3-1.7-1.87.47-3.6,1.37-5.2,2.7-1.4,1-2.97,1.97-4.7,2.9-1.73.73-3.43,1-5.1.8-1.87-.2-3.33-.97-4.4-2.3-1.2-1.73-1.53-3.47-1-5.2.47-1.53,1.17-2.9,2.1-4.1L48.9,0h6l9.5,16.4c-.53-.27-1.1-.53-1.7-.8-1.7-.8-3.4-1.1-4.5-1.3l-6.4-11-11,14.9-.1.1c-.67.8-1.13,1.63-1.4,2.5-.13.53-.03,1.03.3,1.5.27.4.77.63,1.5.7.87.07,1.8-.1,2.8-.5,1.4-.73,2.73-1.57,4-2.5,1.67-1.33,3.4-2.37,5.2-3.1,1.13-.53,2.37-.8,3.7-.8h.3c.7.1,2.8.3,4.8,1.2.73.33,1.4.67,2,1,2.2,1,3.5,1.6,5.1,1.9v-.1c1.07.27,2.1.27,3.1,0,3.1-.9,4.8-3.8,4.8-3.9l4,2.2Z"
                        />
                    </g>
                </svg>
            </Link>
        </StyledLogo>
    )
}

export default Logo
