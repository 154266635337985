import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage } from 'src/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperInstance, { Navigation } from 'swiper'

import {
    InfoWrapper,
    NavButton,
    SliderContainer,
    SliderGrid,
    SliderWrapper,
    StyledHomeSlider,
} from './HomeSlider.style'
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { AnimatePresence, motion } from 'framer-motion'
import { useResponsive } from 'ahooks'
type HomeSliderProps = {
    items: Array<{
        image: CmsImage
        title: string
        subtitle: string
    }>
}

const HomeSlider: React.FC<HomeSliderProps> = ({ items }) => {
    const swiperRef = useRef<SwiperInstance>()
    const [currentSlide, setCurrentSlide] = useState(0)
    const responsiveInfo = useResponsive()
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    })

    const { ref: containerRef, inView: containerInview } = useInView({
        /* Optional options */
        threshold: 0.9,
    })

    useEffect(() => {
        if (inView) {
            document.querySelector('#header')?.classList.add('light')
        } else {
            document.querySelector('#header')?.classList.remove('light')
        }
    }, [inView])

    useEffect(() => {
        if (containerInview && responsiveInfo.md) {
            document.querySelector('#whatsapp')?.classList.add('light')
        } else {
            document.querySelector('#whatsapp')?.classList.remove('light')
        }
    }, [containerInview, responsiveInfo])

    return (
        <StyledHomeSlider ref={ref}>
            <Container as={SliderContainer} ref={containerRef}>
                <SliderGrid>
                    <InfoWrapper as={Padder} top="sm">
                        <AnimatePresence exitBeforeEnter>
                            <Padder
                                key={currentSlide}
                                as={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                top="sm"
                            >
                                <RichText
                                    htmlString={items[currentSlide].subtitle}
                                    textVariant="base2"
                                    className="description"
                                />
                                <Text textVariant="heading1">
                                    {items[currentSlide].title}
                                </Text>
                            </Padder>
                        </AnimatePresence>
                        <FlexWrapper
                            as={Padder}
                            left="sm"
                            justify={{ '@initial': 'center', '@md': 'start' }}
                        >
                            <NavButton
                                type="button"
                                onClick={() => {
                                    swiperRef.current?.slidePrev()
                                }}
                            >
                                <HiArrowLongLeft />
                            </NavButton>
                            <NavButton
                                type="button"
                                onClick={() => {
                                    swiperRef.current?.slideNext()
                                }}
                            >
                                <HiArrowLongRight />
                            </NavButton>
                        </FlexWrapper>
                    </InfoWrapper>
                    <SliderWrapper
                        as={Swiper}
                        slidesPerView={1}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper
                        }}
                        loop
                        onSlideChange={(swiper) =>
                            setCurrentSlide(swiper.realIndex)
                        }
                    >
                        {items.map(({ image: { id, url, alt } }, index) => (
                            <SwiperSlide key={id || index}>
                                <img src={url} alt={alt} />
                            </SwiperSlide>
                        ))}
                    </SliderWrapper>
                </SliderGrid>
            </Container>
        </StyledHomeSlider>
    )
}

export default HomeSlider
