import React from 'react'

import * as Components from 'src/components/_composer'

export interface ComposerProps {
    components: Array<any>
}

const ComposerComponent: React.FC<{ component: string }> = ({
    component,
    ...rest
}) => {
    const TagName =
        (Components as unknown as Record<string, React.FC | React.FC>)[
            component
        ] || null
    return !!TagName ? (
        <TagName {...(rest as any)} />
    ) : (
        <div>{`Component ${component} not found`}</div>
    )
}

const Composer: React.FC<ComposerProps> = ({ components }) => {
    return (
        <>
            {components.map(({ component, ...rest }, index) =>
                component ? (
                    <ComposerComponent
                        key={component.id || `composer-${index}`}
                        component={component}
                        {...rest}
                    />
                ) : null
            )}
        </>
    )
}
export default Composer
